import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/main.scss';
import './registerServiceWorker';
import ScrollAnimation, { animationViewFull } from './directives/scrollAnimation';
import { store } from './store/store';
import VueSession from './helper/VueSession';
import VueGtag from 'vue-gtag';
import 'windi.css';

createApp(App)
    .directive('scrollanimation', ScrollAnimation)
    .directive('scrollview', animationViewFull)
    .directive('click-outside', {
        beforeMount(el, binding) {
            el.clickOutsideEvent = function (event) {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value(event, el);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        },
    })
    .use(store)
    .use(router)
    .use(VueSession, {
        persist: true,
    })
    .use(
        VueGtag,
        {
            appName: 'zdravevski.pro',
            includes: [{ id: 'UA-59209721-1' }],
            config: { id: 'G-PRR5K8PLFB' },
            // onReady() {
            //     //
            // },
        },
        router
    )
    .mount('#app');
