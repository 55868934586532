import { createRouter, createWebHistory } from 'vue-router';
import AboutMe from './../views/AboutMe/AboutMe.vue';
// import { event } from 'vue-gtag';

const routes = [
    {
        path: '/',
        name: 'AboutMe',
        component: AboutMe,
    },
    {
        path: '/experience',
        name: 'Experience',
        component: () => import('./../views/MyExperience'),
    },
    {
        path: '/project',
        name: 'Project',
        component: () => import('./../views/MyWork'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('./../views/ContactMe'),
    },
    {
        path: '/warcraft-books',
        name: 'WarcraftBooks',
        component: () => import('./../views/WarcraftBooks'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import('./../views/PageNotFound'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (!savedPosition) {
            return { left: 0, top: 0 };
        } else {
            return savedPosition;
        }
    },
});

router.afterEach((to) => {
    document.title = `${to.name} - Jane Zdravevski`;
    // console.log('Page Title: ', to.name);

    // event('config', 'UA-59209721-1', {
    //     page_title: to.fullPath,
    //     app_name: 'umren.live',
    //     send_page_view: true,
    // });
});

export default router;
