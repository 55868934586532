<script setup>
import ThemeChanger from './../themeChanger/themeChanger';
import { Home20Filled, Briefcase20Filled, Code20Filled, Mail20Filled, /** WebAsset24Filled **/ Navigation20Regular } from '@vicons/fluent';
import Facebook from '@vicons/fa/Facebook';
import LinkedinIn from '@vicons/fa/LinkedinIn';
import Youtube from '@vicons/fa/Youtube';
import Github from '@vicons/fa/Github';
import Twitter from '@vicons/fa/Twitter';
import Twitch from '@vicons/fa/Twitch';
import Discord from '@vicons/fa/Discord';
import Resume from '@/constant/resume';
import { Icon } from '@vicons/utils';
import { onBeforeMount, onMounted, ref } from 'vue';
import Document16Regular from '@vicons/fluent/Document16Regular';
import { RouterLink } from 'vue-router';
import RenderIcon from './../../components/Icon.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const socialNetworks = [
    { url: 'https://www.facebook.com/umrentv', icon: Facebook },
    { url: 'https://www.linkedin.com/in/zdravevski/', icon: LinkedinIn },
    { url: 'https://www.youtube.com/umrentv', icon: Youtube },
    { url: 'https://discord.gg/umrentv', icon: Discord },
    { url: 'https://www.twitch.tv/umren', icon: Twitch },
    { url: 'https://twitter.com/UmrenTV', icon: Twitter },
    { url: 'https://github.com/UmrenTV', icon: Github },
];

const scroll = ref(0);
const width = ref(0);
const showNavbar = ref(true);
const lastScrollPosition = ref(0);

function handleScroll() {
    scroll.value = window.scrollY;
}
function handleWidth() {
    width.value = window.innerWidth;
}
function onScroll() {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
        return;
    }
    // Here we determine whether we need to show or hide the navbar
    showNavbar.value = currentScrollPosition < lastScrollPosition.value;

    // Set the current scroll position as the last scroll position
    lastScrollPosition.value = currentScrollPosition;
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWidth);
    window.addEventListener('scroll', onScroll);
});

onBeforeMount(() => {
    window.removeEventListener('scroll', onScroll);
});

function openSite(site) {
    window.open(site, '_blank');
}

const openNav = () => {
    store.dispatch('setNav', true);
};
</script>

<template>
    <div class="header" :class="{ 'navbar--hidden': !showNavbar && scroll > 100, 'nav-min': scroll > 50 }">
        <nav>
            <div class="logo-image">
                <h2 class="sm:ml-[50px] ml-10px font-700 text-[var(--primary)] text-size-2.5rem -mt-6px"><router-link to="/">JZ</router-link></h2>
            </div>
            <div class="navs">
                <div class="nav-o">
                    <RouterLink v-scrollanimation style="transition-delay: 100ms" to="/">
                        <div class="flex items-center" :class="{ active: route.name == 'AboutMe' }">
                            <Icon size="20">
                                <Home20Filled />
                            </Icon>
                            <span class="text-size-14px font-500"> Home </span>
                        </div>
                    </RouterLink>
                    <RouterLink v-scrollanimation style="transition-delay: 250ms" to="/experience">
                        <div class="flex items-center" :class="{ active: route.name == 'Experience' }">
                            <Icon size="20">
                                <Briefcase20Filled />
                            </Icon>
                            <span class="text-size-14px font-500"> Experience </span>
                        </div>
                    </RouterLink>
                    <RouterLink v-scrollanimation style="transition-delay: 400ms" to="/project">
                        <div :class="{ active: route.name == 'Project' }">
                            <Icon size="20">
                                <Code20Filled />
                            </Icon>
                            <span class="text-size-14px font-500"> Projects </span>
                        </div>
                    </RouterLink>
                    <RouterLink v-scrollanimation style="transition-delay: 550ms" to="/contact">
                        <div :class="{ active: route.name == 'Contact' }">
                            <Icon size="20">
                                <Mail20Filled />
                            </Icon>
                            <span class="text-size-14px font-500"> Contact Me </span>
                        </div>
                    </RouterLink>
                    <!-- DON'T REMOVE THIS WE'LL NEED WHEN BLOG IS BUILT!  <div v-scrollanimation style="transition-delay: 650ms">
                        <a class="text-size-14px font-500" rel="external" href="https://zdravevski.pro" target="_blank" hreflang="es-es">
                            <Icon size="20">
                                <WebAsset24Filled />
                            </Icon>
                            Blog
                        </a>
                    </div> DON'T REMOVE THIS WE'LL NEED WHEN BLOG IS BUILT! -->
                    <div v-scrollanimation style="transition-delay: 650ms">
                        <a class="text-size-14px font-500" rel="external" :href="Resume.url" hreflang="es-es" target="_blank">
                            <Icon size="20">
                                <Document16Regular />
                            </Icon>
                            Resume
                        </a>
                    </div>
                </div>
                <div v-scrollanimation style="transition-delay: 700ms">
                    <ThemeChanger />
                </div>
            </div>
            <div class="nav-mobile">
                <ol>
                    <li class="mx-1" v-for="network in socialNetworks" :key="network.icon">
                        <div @click="openSite(network.url)" class="a-link">
                            <div class="text-size-25px">
                                <RenderIcon :icon="network.icon" :size="25" />
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
            <div class="show-mobile-nav-but mt-[10px]">
                <Icon size="40" @click="openNav()" style="margin-right: 20px">
                    <Navigation20Regular />
                </Icon>
            </div>
        </nav>
    </div>
</template>
