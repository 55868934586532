import { createStore } from 'vuex';

export const store = createStore({
    state() {
        return {
            // show: false,
            navShow: false,
        };
    },
    mutations: {
        toggleShow(state) {
            state.navShow = !state.navShow;
        },
        navSet(state, payload) {
            state.navShow = payload;
        },
    },
    actions: {
        toggleShow(context) {
            context.commit('toggleShow');
        },
        setNav(context, payload) {
            context.commit('navSet', payload);
        },
    },
    getters: {
        getNavShow(state) {
            return state.navShow;
        },
    },
});
